<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">Services</h2>
    </template>
    <template #page-content>
      <b-loading
        :is-full-page="true"
        v-model="isLoading"
        :can-cancel="true"
      ></b-loading>
      <div class="columns">
        <r-card class="column is-10 is-offset-1" style="padding: 25px">
          <div class="columns">
            <div class="column is-12 parent">
              <b-icon
                icon="arrow-left"
                style="margin-right: 5px; cursor: pointer"
                class="child"
                @click.native="backCheckout"
              ></b-icon>
              <span class="child">Pembayaran</span>
            </div>
          </div>
          <hr style="background: #f9d6d6; margin-top: 0px" />
          <div class="columns" style="margin-top: 20px">
            <div class="column is-three-fifths is-offset-one-fifth">
              <div class="columns" style="font-weight: bold">
                <div class="column is-6">Total Pembayaran</div>
                <div class="column is-6" style="text-align: right">
                  Rp. {{ billing ? billing.price : null | currency }}
                </div>
              </div>
              <hr style="background: #f9d6d6; margin-top: -10px" />
              <div class="columns is-multiline">
                <div class="column is-12">
                  Transfer Bank BCA (Dicek manual)
                  <br />
                  <div style="text-indent: 30px; margin-top: 10px">
                    No. Rekening BCA
                    <span style="font-weight: bold">A.N. John Doe</span>
                    :
                    <div style="text-indent: 30px; font-weight: bold">
                      <span
                        style="
                          font-size: 20px;
                          margin-right: 10px;
                          color: #ff3b3b;
                        "
                      >
                        {{ noRekt }}
                      </span>
                      <span
                        style="
                          font-weight: 15px;
                          color: #68a45d;
                          cursor: pointer;
                        "
                        @click="copyToClipBoard"
                      >
                        SALIN
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  class="column is-12"
                  style="font-size: 12px; padding-top: 0"
                >
                  1. Gunakan ATM / iBanking / mBanking / Setor Tunai untuk
                  transfer ke rekening Roketin di atas.
                  <br />
                  2. Silahkan upload bukti transfer setelah melakukan pembayaran
                  ke rekening tersebut.
                  <br />
                  3. Demi keamanan transaksi, mohon untuk tidak membagikan bukti
                  atau konfirmasi pembayaran pesanan kepada siapapun, selain
                  mengunggahnya via aplikasi Space ID.
                </div>
                <div class="column is-12">
                  <div class="columns">
                    <div class="column is-half is-offset-one-quarter">
                      <b-button expanded @click="openModalUploadImage">
                        Upload Bukti Transfer Sekarang
                      </b-button>
                      <b-button
                        type="is-primary"
                        style="margin: 10px 0"
                        expanded
                        outlined
                        @click="redirectToPaymentDashboard"
                      >
                        Upload Bukti Transfer Nanti
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </r-card>
      </div>
      <ModalUploadImage
        :id="$route.params.id"
        :key="keyModalUploadImage"
        v-if="isModalUploadImage"
      />
    </template>
  </r-page>
</template>

<script>
import ModalUploadImage from './ModalUploadImage.vue'
export default {
  components: {
    ModalUploadImage,
  },
  created() {
    this.fetchDataBilling(this.$route.params.id)
  },
  data() {
    return {
      isLoading: true,
      isModalUploadImage: false,
      keyModalUploadImage: 0,
      noRekt: '789 9821 981',
      billing: null,
    }
  },
  methods: {
    fetchDataBilling(id) {
      this.$store.dispatch('payments/getBilling2', id).then((data) => {
        this.billing = data
        this.isLoading = false
      })
    },
    openModalUploadImage() {
      this.keyModalUploadImage++
      this.isModalUploadImage = true
    },
    backCheckout() {
      this.$router.push({
        name: 'checkout-billing-package',
        params: {
          id: this.$route.params.id,
        },
      })
    },
    copyToClipBoard() {
      navigator.clipboard.writeText(this.noRekt)
    },
    redirectToPaymentDashboard() {
      this.$router.push({
        name: 'dashboard-payment',
      })
    },
  },
  filters: {
    currency(num) {
      if (!num) {
        return '0.00'
      }
      // const number = (num / 1).toFixed(2).replace('.', '.')
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
  },
}
</script>

<style scoped>
.parent {
  display: flex;
  justify-content: left;
  align-items: left;
}

.child {
  flex-shrink: 0;
}
</style>
