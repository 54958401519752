<template>
  <r-page-modal :isModal="true">
    <template #page-header>
      <h3 class="page-title">Upload Bukti Transfer</h3>
    </template>
    <template #page-content>
      <section>
        <b-field>
          <b-upload
            v-model="myFile"
            multiple
            drag-drop
            expanded
            accept="image/*"
            v-if="!url"
            @change.native="onChange"
          >
            <section class="section" style="width: 100%">
              <div class="content has-text-centered">
                <p>
                  <b-icon icon="image" size="is-large"></b-icon>
                </p>
                <p>Drop your files here or click to upload</p>
              </div>
            </section>
          </b-upload>
        </b-field>

        <div id="preview" v-if="url">
          <img :src="url" />
        </div>

        <div class="tags">
          <span
            v-for="(file, index) in myFile"
            :key="index"
            class="tag is-primary"
          >
            {{ file.name }}
            <button
              class="delete is-small"
              type="button"
              @click="deleteDropFile(index)"
            ></button>
          </span>
        </div>
      </section>
      <b-button expanded class="mt-2" @click="saveUpload">Upload</b-button>
    </template>
  </r-page-modal>
</template>

<script>
export default {
  props: {
    id: Number,
  },
  data() {
    return {
      myFile: [],
      url: null,
      billing: null,
    }
  },
  computed: {
    getEnv() {
      console.log(process.env)
      return process.env.VUE_APP_API_URL
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    onChange() {
      let file = this.myFile[0]
      this.url = URL.createObjectURL(file)
    },
    fetchData() {
      this.$store.dispatch('payments/getBilling2', this.id).then((data) => {
        this.billing = data
        if (this.billing.image && this.billing.image != '') {
          this.url = `${this.getEnv}/static/${this.billing.image}`
          let file = [
            {
              name: this.billing.image,
            },
          ]
          this.myFile = file
          console.log(this.myFile)
        }
      })
    },
    deleteDropFile(index) {
      this.url = null
      this.myFile.splice(index, 1)
    },
    saveUpload() {
      let formData = new FormData()
      formData.append('myFile', this.myFile[0])
      formData.append('id', this.id)
      this.$store.dispatch('payments/uploadImage', formData).then(() => {
        this.$swal({
          icon: 'success',
          title: 'Success!',
          text: `Bukti Transfer sudah di upload!`,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'button is-success',
          },
        })
        this.$router.push({
          name: 'dashboard-payment',
        })
      })
    },
  },
}
</script>
